import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './index..css';

function Disclaimer() {
  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="primary" onClick={handleShow} className='Disclaimer'>
        Legal Disclaimer
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Legal Disclaimer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>The information contained on this website is for general informational purposes only. It is not intended to be a substitute for professional advice or opinion. You should always consult with a qualified professional before making any important decisions.</p>

          <p>This website may contain errors or omissions. The information contained on this website is not guaranteed to be accurate, complete, or up-to-date.</p>

          <p>RL-Trader LLC disclaims any liability for any loss or damage arising from the use of this website.</p>

          <p>By using this website, you agree to these terms and conditions.</p>

          <h5>Additional Disclaimers</h5>

          <p>RL-Trader LLC is not responsible for the content of any third-party websites that are linked to this website.</p>
          <p>RL-Trader LLC is not responsible for any damage or loss caused by viruses or other malware that may be downloaded from this website.</p>
          <p>RL-Trader LLC reserves the right to change or update this disclaimer at any time.</p>
         
          {/* Contact Information

          If you have any questions about this disclaimer, please contact the LLC at:

          [Company Name]
          [Address]
          [Phone Number]
          [Email Address] */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Disclaimer;