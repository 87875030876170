import { useRef, useEffect, useState} from 'react';
import './index..css'
import { scaleLinear } from 'd3-scale'
import { select } from 'd3-selection'
import * as d3 from 'd3'

const Plotter = ({productId}) => {
    const svgRef = useRef();
    // let svg = svgRef.current;
    
    const [initial_data, newInitialData] = useState(false);
    const [data, newData] = useState(["Data Updated"]);
    const [tradeData, newTradeData] = useState(["Get Data"]);

    // const [visibilityState, setVisibilityState] = useState('visible')

    // // For when tab is changed or refreshed
    // useEffect(() => {
    //     setVisibilityState(document.visibilityState)
    // }, [document.visibilityState])


    useEffect(() => {
        const fetchInitialData = async () => {
            try {
                console.log("Initital fetch data")
                // const result = await fetch('http://127.0.0.1:5001/api/past_trades/' + productId, {method: 'get', mode: 'cors'});
                const result = await fetch('https://market-stream-backend.happytree-11eb673f.westus.azurecontainerapps.io/api/past_trades/' + productId, {method: 'get', mode: 'cors'});
                const json = await result.json();
                const ok = await result.ok;
                newData(json);
                newInitialData(ok);
            } catch (error) {
                console.log(error);
            }
        }
        fetchInitialData();
    }, [productId, ]);


    useEffect(() => {
        if (initial_data === true) {
            const svg = select(svgRef.current);
            svg
                .selectAll("g")
                .enter()

            // Test Data
            //     random = d3.randomNormal(100, 200),
            //     data = d3.range(n).map(random)
            console.log("initial data 2")
            // Define margins for Axes and Plot boundary using outer grid css as window mods
            d3.selectAll("g.plot").remove()
            var margin = {top: 10, right: 30, bottom: 40, left: 60},
                width = +window.innerWidth - margin.left - margin.right,
                height = +(svgRef.current.clientHeight) - margin.top - margin.bottom,
                plot = svg.append('g').attr('transform', 'translate(' + margin.left + "," + margin.top + ')').attr('class', 'plot')

            console.log(data)
            
            var x = d3.scaleTime()
                .domain(d3.extent(data, function(d) { 
                    return d3.isoParse(d.date); 
                }))
                .range([0, width]);
            
            var min_val = d3.min(data, function(d) {
                return +d.price;
            })
            var max_val = d3.max(data, function(d) {
                return +d.price;
            })
            var extent = max_val - min_val

            var y = scaleLinear()
                .domain([min_val - extent, max_val + extent])
                .range([height, 0])
            
            var line = d3.line()
                .x(function(d) { return x(d3.isoParse(d.date)); })
                .y(function(d) { return y(+d.price); })
            
            plot
                .append("g")
                .attr("class", "axisX")
                .attr("transform", "translate(0," + height + ")")
                .call(d3.axisBottom(x))
            
            plot
            .append("g")
            .attr("class", "axisY")
            .attr("transform", "translate(0," + 0 + ")")
            .call(d3.axisLeft(y).ticks(20).tickFormat(d3.format("$.2f")))

            plot
                .append('clipPath')
                    .attr('id', 'clip')
                .append('rect')
                    .attr('width', width)
                    .attr('height', height)
            
            plot
                .append('g')
                    .attr('class', 'line-group')
                    .attr('clip-path', 'url(#clip)')
                        .append('path')
                            .datum(data)
                            .attr('class', 'line')
                            .attr('d', line)
            
            d3.selectAll("div.tooltip").remove()
            var div = d3.select("body").append("div")
                .attr("class", "tooltip")
                .style("opacity", 0)

            d3.select(".line-group").selectAll('circle')
                .data(data)
                .enter()
                .append('circle')
                    .attr('class', 'dot')
                    .attr('r', 3)
                    // Cicle bordering
                    // .attr("stroke", "#33c23d")
                    // .attr("stroke-width", 1)
                    .attr('fill', '#33c23d')
                    .attr('cx', function(d) { return x(d3.isoParse(d.date));})
                    .attr('cy', function(d) { return y(+d.price);})
                    .on('mouseover', function (event, d) {
                        d3.select(this).transition()
                              .duration('50')
                              .attr("r", 7);
                        div.transition()
                             .duration(100)
                             .style("opacity", 1);
                        div.html("$" + d3.format(".2f")(+d.price))
                             .style("left", (event.x + (10 + 60 * Math.sin( (Math.PI/2)*(event.x / window.innerWidth) - Math.PI ) ) + "px"))
                             .style("top", (event.y - 15) + "px");
                   })
                   .on('mouseout', function (event, d) {
                        d3.select(this).transition()
                             .duration('50')
                             .attr("r", 3);
                        div.transition()
                             .duration('50')
                             .style("opacity", 0);
                   })
            
            d3.select('.line-group').selectAll('circle')
                .filter(function(d) { return d.action === "BUY"; })
                .attr('class', 'dot')
                .attr('r', 3)
                .attr('fill', '#005AB5')
                .attr('cx', function(d) { return x(d3.isoParse(d.date));})
                .attr('cy', function(d) { return y(+d.price);})
            
            d3.select('.line-group').selectAll('circle')
                .filter(function(d) { return d.action === "SELL"; })
                    .attr('class', 'dot')
                    .attr('r', 3)
                    .attr('fill', '#DC3220')
                    .attr('cx', function(d) { return x(d3.isoParse(d.date));})
                    .attr('cy', function(d) { return y(d.price);})
            
            d3.select(".line-group").selectAll('circle')

            
            const fetchData = async () => {
                try {
                    console.log("fetching data")
                    // const result = await fetch('http://127.0.0.1:5001/api/trades/' + productId, {method: 'get', mode: 'cors'});
                    const result = await fetch('https://market-stream-backend.happytree-11eb673f.westus.azurecontainerapps.io/api/trades/' + productId, {method: 'get', mode: 'cors'});
                    const json = await result.json();
                    newTradeData(json);
                } catch (error) {
                    console.log(error);
                }
            }

            let interval = setInterval(() => {
                fetchData();
                // console.log(data)
            }, 100);

            return () => clearInterval(interval);
        }
    }, [data, initial_data, productId]);


    useEffect(() => {
        if (tradeData["price"] !== undefined && tradeData["date"] !== data[data.length-1]["date"]) {
            // Define margins for Axes and Plot boundary using outer grid css as window mods
            var margin = {top: 10, right: 30, bottom: 40, left: 40},
            width = +window.innerWidth - margin.left - margin.right,
            height = +(svgRef.current.clientHeight) - margin.top - margin.bottom
            
            var x = d3.scaleTime()
                .domain(d3.extent(data, function(d) { 
                    return d3.isoParse(d.date); 
                }))
                .range([0, width]);

            var min_val = d3.min(data, function(d) {
                return +d.price;
            })
            var max_val = d3.max(data, function(d) {
                return +d.price;
            })
            var extent = max_val - min_val

            var y = scaleLinear()
                .domain([min_val - extent, max_val + extent])
                .range([height, 0])
            
            var line = d3.line()
                .x(function(d) { return x(d3.isoParse(d.date)); })
                .y(function(d) { return y(+d.price); })
            

            // var points = 360

            var trade = {"date": tradeData["date"], "price": tradeData["price"], "action": tradeData["action"]}
            data.push(trade)
            console.log(trade)
            console.log(data)
            
            d3.select(".axisX")
                .attr("transform", "translate(0," + height + ")")
                .call(d3.axisBottom(x))
            
            d3.select(".axisY")
                .attr("transform", "translate(0," + 0 + ")")
                .call(d3.axisLeft(y).ticks(20).tickFormat(d3.format("$.2f")))
            
            d3.select("#clip")
                .attr('width', width)
                .attr('height', height)
        
            // d3.select(".line-group")
            //     .datum(data)

            d3.select("path.line")
                .datum(data)
                .attr('d', line)
                .attr('transform', null)
                // .transition()
                // .duration(100)
                // .ease(d3.easeLinear)
                //     .attr('d', line)
                //     .attr('transform', 'translate(' + -(width/points) + ',0)')

            
            // d3.select("path.line")
            //     .exit()
            //     .append('path')
            //         .attr('class', 'line')
            //         .attr('d', line)
            // d3.select('.line-group').selectAll('circle')
            //     .data(data, function(e) { return e; })
            //     .enter()
            //     .append('circle')
            //         .attr('class', 'dot')
            //         .attr('r', 0)
            //         .attr('cx', function(d) { return x(d3.isoParse(d.date));})
            //         .attr('cy', function(d) { return y(d.price);})
            //         .exit().remove()

            d3.select('.line-group').selectAll('circle')
            .data(data)
            .filter(function(d) { return d.action === "None"; })
                    .attr('class', 'dot')
                    .attr('r', 2)
                    .attr('fill', '#33c23d')
                    .attr('cx', function(d) { return x(d3.isoParse(d.date));})
                    .attr('cy', function(d) { return y(d.price);})
            
            d3.select('.line-group').selectAll('circle')
                .filter(function(d) { return d.action === "BUY"; })
                .attr('class', 'dot')
                .attr('r', 3)
                .attr('fill', '#005AB5')
                .attr('cx', function(d) { return x(d3.isoParse(d.date));})
                .attr('cy', function(d) { return y(+d.price);})

            
            d3.select('.line-group').selectAll('circle')
                .filter(function(d) { return d.action === "SELL"; })
                    .attr('class', 'dot')
                    .attr('r', 3)
                    .attr('fill', '#DC3220')
                    .attr('cx', function(d) { return x(d3.isoParse(d.date));})
                    .attr('cy', function(d) { return y(d.price);})


            
            d3.select('.line-group').selectAll('circle')
                .exit().remove()
            d3.select('path.line').selectAll('line')
                .exit().remove()
            data.shift()
        }
    }, [data, tradeData]);

    return (
        <div className='plot-box'>
          <svg ref={svgRef} width="100%" height="100%">
          </svg>
        </div>
      );
}

export default Plotter;