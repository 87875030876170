import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useState } from 'react';
import './index..css';

function Menu({productIdHook}) {

  // const [email, newEmail] = useState(["Form Submitted"]);
  const [submitted, setSubmitted] = useState([false]);

  const onSubmit = (e) => {
    e.preventDefault();
    console.log("refresh prevented");
    // setSubmitted(true);
    console.log(e.target.email.value);

    const postData = async () => {
      try {
        console.log("posting data");
        const data = JSON.stringify({email: e.target.email.value});
        // const response = await fetch('http://127.0.0.1:5001/api/email_list', {
        const response = await fetch('https://market-stream-backend.happytree-11eb673f.westus.azurecontainerapps.io/api/email_list', {
          method: 'POST', 
          mode: 'cors', 
          cache: 'no-cache', 
          credentials: 'same-origin',
          headers: {
            'Content-Type': 'application/json'
          },
          redirect: 'follow', 
          referrerPolicy: 'no-referrer',
          body: data
        })
        const ok = await response.ok
        setSubmitted(ok)
      } catch (error) {
        console.log(error)
        console.log(submitted)
      }
    }
    postData();
  }

  function handleProductId (e) {
    let value = e.target.value
    console.log(value)
    productIdHook(value)
  }


  return (
    <div className="Menu">
      <div className='Dropdowns'>
        <h5>RL-Trader Model Selection</h5>
        <Form.Select aria-label="Select Market" className='Market-select' id='Ma-select' onChange={handleProductId}>
          <option value="BTC-USD">Bitcoin</option>
          <option value="ETH-USD">Ethereum</option>
        </Form.Select>
        <Form.Select aria-label="Select Model" className='Model-select' id='Mo-select'>
          {/* <option value="1">10 layer Sequental NN | Simple-MA-50</option>
          <option value="2">5 layer Sequential NN | Bollinger-STDEV-2</option> */}
          <option value="3">5 layer LSTM DQN Agent | Bollinger-STDEV-2</option>
        </Form.Select>
        {/* <div className='Model-performance'>
          <h5>24hr Model Performance</h5>
          <p> 18.7654% </p>
        </div> */}
      </div>
      <div className='Description'>
        <h5>About</h5>
        <p>
          This is a public demo of RL-Trader, a platform for training, deployment and monitoring of deep reinforcement learning models for automated cryptocurrency trading.
        </p>
        <p>
          The plot is a live-stream view for 1 of our basic models. Buys are marked in <span style={{color: "#005AB5"}}>blue</span>, 
          sells in <span style={{color: "#DC3220"}}>red</span>.
          {/* Additionally, past 24hr performance is displayed for each model. */}
        </p>
      </div>
      <div className='Description'>
        <h5> Further Information </h5>
        <p>
         Our private platform has an internal database of numerous technical indicators, covariate datasets and trained models. 
         As well, it is capable of active trade execution via exchange APIs for Bitcoin, Ethereum and other cryptocurrencies.
        </p>
        <p>
        If interested in accessing the full platform, please join our waitlist:
        </p>
        <Form onSubmit={onSubmit}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control type="email" name="email" placeholder="Email:" className='email-text'/>
            <Button variant="primary" type="submit" className='button-submit'> Submit </Button>
          </Form.Group>
        </Form>

      </div>
    </div>
  );
}

export default Menu;
