import Plotter from '../Plotter';
import Menu from '../Menu';
import Disclaimer from '../Disclaimer';
import './index..css';
import logo from '../../images/logo.svg'
import MediaQuery from 'react-responsive';
import React, {useState} from 'react';

function App() {
  const [productId, setProductId] = useState(["BTC-USD"]);

  return (
    <div className="App">
      <header  className="App-header">
        <div className='container-logo'>
          <img src={logo} className="App-logo" alt="logo" />
          <div className='App-name'> RL-Trader </div>
        </div>
        <div className='container-title'>
          &nbsp; 
        </div>
      </header>
      <MediaQuery minDeviceWidth={992}>
        <div className='container-app-default'>
          <div className='container-menu-default'><Menu productIdHook={setProductId}/></div>
          <div className='container-plot-default'><Plotter productId={productId} data={[1,2,3,4,5,6,7,8,9,10]} size={[500, 500]}/></div>
        </div>
      </MediaQuery>

      <MediaQuery maxDeviceWidth={991} orientation='portrait'>
        <div className='container-app-mobile'>
          <div className='container-plot-mobile'><Plotter productId={productId} data={[1,2,3,4,5,6,7,8,9,10]} size={[500, 500]}/></div>
          <div className='container-menu-mobile'><Menu productIdHook={setProductId}/></div>
        </div>
      </MediaQuery>

      <MediaQuery maxDeviceWidth={991} orientation='landscape'>
        <div className='container-app-mobile-landscape'>
          <div className='container-plot-mobile-landscape'><Plotter productId={productId} data={[1,2,3,4,5,6,7,8,9,10]} size={[500, 500]}/></div>
          <div className='container-menu-mobile-landscape'><Menu productIdHook={setProductId}/></div>
        </div>
      </MediaQuery>

      <footer>
        <p></p>
        <p>&copy; Copyright 2018 - 2023 RL-Trader LLC &mdash; All rights reserved &nbsp;</p>
        <div className='container-disclaimer'><Disclaimer/></div>
      </footer>
    </div>
  );
}

export default App;
